// cartStore.ts
import {defineStore} from 'pinia';
import {
    addProductToCart,
    fetchActiveCartItems,
    fetchCartItemById,
    deleteCartItem,
    confirmCart,
    fetchCheckedOutCarts,
    adminRemoveCartItem,
} from '@/shared/api/cartApi';
import {Product} from '@/stores/product'; // Make sure to import Product type

export const calculatePriceForKGSoldByUnit = (product: Product, quantity: number, selectedUnityPrice: string, price:number) => {
    let itemPrice = 0;

    if (selectedUnityPrice === 'KG') {
        itemPrice = price * quantity;
    } else if (selectedUnityPrice === 'PZ') {
        const weight = product.UnityWeight || product.MediumUnityWeight || 1;
        itemPrice = price * quantity * weight;
    }
    return itemPrice;
};

export const calculatePrice = (item: { product: Product; quantity: number; selectedUnityPrice: string }) => {
    const {product, quantity, selectedUnityPrice} = item;
    let itemPrice = 0;
    let normalizedQuantity = parseFloat(quantity.toString().replace(',', '.'));
    normalizedQuantity = (isNaN(normalizedQuantity) || normalizedQuantity == 0) ? 1 : normalizedQuantity;

    if (product.UnityWeight === 0) {
        product.UnityWeight = undefined;
    }
    if (product.UnityPrice === 'PZ') {
        itemPrice = product.Price * normalizedQuantity;
    } else if (product.UnityPrice === 'KG' && product?.category?.SoldByUnit) {
        itemPrice = calculatePriceForKGSoldByUnit(product, normalizedQuantity, selectedUnityPrice, product.Price);
    } else {
        itemPrice = product.Price * normalizedQuantity * (product.UnityWeight || 1);
    }
    return itemPrice.toFixed(2);
};
export interface CartItemInput {
    ID: string;
    productID: string;
    quantity: number;
    note?: string;
    price: number;
  }

export interface Cart {
    ID: string;
    note: string;
     createdAt: string;
    items: CartItem[];
    status: string;
  }
export interface CartItem {
    price: number;
    product: {
        Name: string;
    };
    quantity: number;
  }
export const useCartStore = defineStore({
    id: 'cart',
    state: () => ({
        cartItems: [] as Array<{ product: Product; quantity: number, ID: string, note: string, selectedUnityPrice: string }>,
        cartId: null as string | null,
        carts: [] as Array<any>,
        note: null as string|null,
        isOrderModalVisible: false,
        selectedOrder: null as Cart | null,
    }),
    getters: {
        cartItemCount: (state) => state.cartItems.length,
        totalPrice: (state) => {
            return state.cartItems.reduce((total, item) => {
                return total + parseFloat(calculatePrice(item));
            }, 0).toFixed(2);
        },
    },
    actions: {
        async fetchCarts() {
            const cartsData = await fetchCheckedOutCarts();
            if (cartsData) {
                this.carts = cartsData.map((cart: any) => ({
                    ...cart,
                    items: cart.items.map((item: any) => ({
                        ...item,
                        product: {
                            ...item.product,
                            Price: item.price, // Add the price from the cart item to the product
                        },
                    })),
                    total: cart.items.reduce((sum: number, item: any) => {
                        let itemTotal;
                        if (item.product.UnityPrice === 'KG' && item.product?.category?.SoldByUnit) {
                            itemTotal = calculatePriceForKGSoldByUnit(
                                item.product,
                                item.quantity,
                                item.selectedUnityPrice,
                                item.price,
                            );
                        } else {
                            itemTotal = parseFloat(calculatePrice({
                                product: {...item.product, Price: item.price},
                                quantity: item.quantity,
                                selectedUnityPrice: item.selectedUnityPrice,
                            }));
                        }
                        return sum + itemTotal;
                    }, 0),
                }));
            }
        },
        formatDate(isoDateString: string | number | Date) {
            const date = new Date(isoDateString);
            const formattedDate = `${date.getDate().toString().padStart(2, '0')}/
            ${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
            const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
            return {date: formattedDate, time: formattedTime};
        },
        async fetchCartItems() {
            const {cartId, note, items} = await fetchActiveCartItems();
            this.cartId = cartId;
            this.note = note;

            this.cartItems = items.map((item: { product: Product; quantity: number, ID:string, note:string }) => {
                this.updateProductPrice(item.product);
                return item;
            });
        },
        async addProductToCart(product: Product, quantity: number, note?: string|null, selectedUnityPrice?:string|null) {
            const cartItem = await addProductToCart(product.uid, quantity, note, selectedUnityPrice);
            await this.updateOrAddCartItem(cartItem.ID);
        },
        async updateOrAddCartItem(cartItemId: string) {
            const cartItemWithCartId = await fetchCartItemById(cartItemId);
            if (cartItemWithCartId) {
                const {cartItem, cartId} = cartItemWithCartId;
                // Save the cartId in the store
                this.cartId = cartId;

                if (cartItem && cartItem.product) {
                    // Update product price if necessary
                    this.updateProductPrice(cartItem.product);

                    const existingIndex = this.cartItems.findIndex((item) => item.product.uid === cartItem.product.uid);
                    if (existingIndex !== -1) {
                        // Update existing item with possibly updated price
                        this.cartItems[existingIndex] = cartItem;
                    } else {
                        // Add new item with possibly updated price
                        this.cartItems.push(cartItem);
                    }
                }
            }
        },
        updateProductPrice(product: Product) {
            if (product.listedAtConnection && product.listedAtConnection.edges.length > 0) {
                const newPrice = product.listedAtConnection.edges[0].price;
                if (newPrice !== undefined) {
                    product.Price = newPrice; // Update the product's price
                }
            }
        },
        async removeCartItem(cartItemId: string) {
            const result = await deleteCartItem(cartItemId);
            if (result && result.nodesDeleted) {
                this.cartItems = this.cartItems.filter((item) => item.ID !== cartItemId);
            }
        },
        async adminRemoveCartItem(cartId: string, itemId: string) {
            const result = await adminRemoveCartItem(cartId, itemId);
            if (result && result.nodesDeleted) {
                const cart = this.carts.find((cart) => cart.ID === cartId);
                if (cart) {
                    cart.items = cart.items.filter((item: { ID: string }) => item.ID !== itemId);
                }
            }
        },

        async confirmOrder() {
            if (!this.cartId || this.cartItems.length === 0) {
                console.error('No active cart or cart items to confirm');
                return;
            }

            // Prepare cart items input
            const cartItemsInput = this.cartItems.map((item) => ({
                ID: item.ID,
                productID: item.product.uid,
                quantity: item.quantity,
                price: item.product.Price, // Ensure this matches your frontend calculation
            }));

            const response = await confirmCart(this.cartId, this.note, cartItemsInput);
            if (response && response.success) {
                console.log('Order confirmed:', response.message);
                this.cartItems = []; // Clear the cart items after successful confirmation
                this.cartId = null; // Reset the cart ID
            } else {
                console.error('Failed to confirm order:', response?.message);
            }
        },
        setSelectedOrder(order: Cart) {
            this.selectedOrder = order;
        },
        showOrderModal(orderId: string) {
            const order = this.carts.find((o) => o.ID === orderId);
            this.selectedOrder = order;
            this.isOrderModalVisible = true;
        },
        hideOrderModal() {
            this.isOrderModalVisible = false;
            this.selectedOrder = null;
        },
    },
});
