export default {
    // f2BaseURL: 'http://localhost:3000',
    f2BaseURL: 'https://fedrogustoautentico.framework2.dev',
    mobileAppUrl: 'https://fedromobile.framework2.dev',
    filesApi: '/app-key/fedro/api/files/',
    graphqlURL: '/api/graphql',
    unAuthedGraphqlURL: '/public/graphql',
    applicationKey: 'fedro',
    restaurantRoleUid: '0001687845225058-XTNu04bqd7',
    supplierRoleUid: '0001688545441887-mc9Ej5exW6',
    development: false,
    appStoreUrl: 'https://testflight.apple.com/join/nQPuR61A',
    playStoreUrl: 'https://play.google.com/apps/internaltest/4700654689170762416',
};
