<template>
    <ion-modal
        :is-open="cartStore.isOrderModalVisible && cartStore?.selectedOrder?.ID"
        @did-dismiss="closeModal"
    >
        <ion-header>
            <ion-toolbar>
                <ion-title>Dettagli dell'ordine</ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="closeModal">
                        Chiudi
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
            <div
                v-if="cartStore.selectedOrder"
                class="order-details"
            >
                <div class="order-header">
                    <h2>Ordine #{{ formatOrderId(cartStore.selectedOrder.ID) }}</h2>
                    <p><strong>Data:</strong> {{ formatDate(cartStore.selectedOrder.confirmedAt) }}</p>
                    <p><strong>Stato:</strong> {{ translateStatus(cartStore.selectedOrder.status) }}</p>
                    <p v-if="cartStore.selectedOrder.note">
                        <strong>Nota:</strong> {{ cartStore.selectedOrder.note }}
                    </p>
                </div>

                <ion-list class="item-list">
                    <ion-item-group>
                        <ion-item-divider sticky>
                            <ion-label>Dettagli Prodotti</ion-label>
                        </ion-item-divider>
                        <ion-item
                            v-for="item in cartStore.selectedOrder.items"
                            :key="item.ID"
                            button
                            @click="goToProduct(item.product.uid)"
                        >
                            <ion-label class="product-details">
                                <div class="product-name">
                                    {{ truncateText(item.product.Name, 50) }}
                                    <ion-icon
                                        v-if="item.product.Name.length > 50"
                                        :icon="informationCircleOutline"
                                        class="info-icon"
                                        @click.stop="showFullText(item.product.Name)"
                                    />
                                </div>
                                <div
                                    v-if="item.product.producedBy"
                                    class="producer-name"
                                >
                                    {{ truncateText(item.product.producedBy.Name, 50) }}
                                    <ion-icon
                                        v-if="item.product.producedBy.Name.length > 50"
                                        :icon="informationCircleOutline"
                                        class="info-icon"
                                        @click.stop="showFullText(item.product.producedBy.Name)"
                                    />
                                </div>
                                <div
                                    v-if="item.note"
                                    class="note"
                                >
                                    Nota: {{ truncateText(item.note, 50) }}
                                    <ion-icon
                                        v-if="item.note.length > 50"
                                        :icon="informationCircleOutline"
                                        class="info-icon"
                                        @click.stop="showFullText(item.note)"
                                    />
                                </div>
                                <div class="quantity-price">
                                    <span class="quantity">{{ formatQuantity(item) }}</span>
                                    <span class="price">{{ formatCurrency(calculateItemTotal(item)) }}</span>
                                </div>
                            </ion-label>
                        </ion-item>
                    </ion-item-group>
                </ion-list>

                <div class="total-amount-container">
                    <h6>
                        <strong>Totale:</strong> {{ formatCurrency(cartStore.selectedOrder.total) }}
                    </h6>
                </div>
            </div>
        </ion-content>
    </ion-modal>
</template>

<script setup>
import {IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent,
    IonList, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonIcon} from '@ionic/vue';
import {useCartStore, calculatePrice} from '@/shared/store/cartStore';
import {useRouter} from 'vue-router';
import {informationCircleOutline} from 'ionicons/icons';
import {toastController} from '@ionic/vue';

const cartStore = useCartStore();
const router = useRouter();

function closeModal() {
    cartStore.hideOrderModal();
}

function formatCurrency(value) {
    return `€${Number(value).toFixed(2)}`;
}

function calculateItemTotal(item) {
    return calculatePrice({
        product: {...item.product, Price: item.price},
        quantity: item.quantity,
        selectedUnityPrice: item.selectedUnityPrice || item.product.UnityPrice,
    });
}

function goToProduct(productUID) {
    closeModal();
    router.push(`/single-product/${productUID}`);
}

function formatDate(isoDateString) {
    const date = new Date(isoDateString);
    return date.toLocaleString('it-IT', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });
}

function translateStatus(status) {
    const statusMap = {
        'CHECKED_OUT': 'Confermato',
        'ACTIVE': 'Attivo',
    // Add more status translations as needed
    };
    return statusMap[status] || status;
}

function formatQuantity(item) {
    const quantity = item.quantity;
    const unit = item.selectedUnityPrice === 'KG' ? 'kg' : 'pz';
    return `${quantity} ${unit}`;
}

function formatOrderId(orderId) {
    if (orderId && orderId.length > 5) {
        return orderId.slice(-5);
    }
    return orderId;
}

function truncateText(text, maxLength) {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
}

async function showFullText(text) {
    const toast = await toastController.create({
        message: text,
        duration: 2000,
        position: 'middle',
    });
    await toast.present();
}
</script>

<style scoped>
.order-details {
  max-width: 800px;
  margin: 0 auto;
}

.order-header {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.order-header h2 {
  margin-top: 0;
  color: #333;
}

.order-header p {
  margin: 5px 0;
  color: #666;
}

.item-list {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.product-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.product-name {
  font-weight: 500;
  font-size: 1em;
}

.producer-name, .note {
  font-size: 0.85em;
  color: #666;
}

.quantity-price {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  margin-top: 5px;
}

.info-icon {
  margin-left: 5px;
  font-size: 14px;
  color: var(--ion-color-medium);
  vertical-align: middle;
}

.total-amount-container {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  background-color: #f7f7f7;
  border-radius: 0 0 8px 8px;
  margin-top: 10px;
}

.total-amount-container h6 {
  font-size: 1.1em;
  margin: 0;
}
</style>
