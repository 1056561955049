<template>
    <ion-modal :is-open="isOpen">
        <ion-header>
            <ion-toolbar>
                <ion-title>Seleziona una Provincia</ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="close">
                        Chiudi
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
            <ion-searchbar v-model="searchText" />
            <ion-list>
                <ion-item
                    v-for="province in filteredProvinces"
                    :key="province"
                    button
                    @click="selectProvince(province)"
                >
                    {{ province }}
                </ion-item>
            </ion-list>
        </ion-content>
    </ion-modal>
</template>

<script setup>
import {
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonSearchbar,
    IonList,
    IonItem,
} from '@ionic/vue';
import {ref, defineProps, computed} from 'vue';

const props = defineProps({
    provinces: Array,
});
const isOpen = ref(false);
const searchText = ref('');
const emit = defineEmits(['update:selectedProvince']);

const filteredProvinces = computed(() => {
    let provincesList = props.provinces.map((province) => province.Province);

    if (searchText.value) {
        provincesList = provincesList.filter((provinceName) =>
            provinceName.toLowerCase().includes(searchText.value.toLowerCase()),
        );
    }

    // Sort the provincesList array in alphabetical order
    provincesList.sort((a, b) => a.localeCompare(b));

    return provincesList;
});

const selectProvince = (province) => {
    emit('update:selectedProvince', province);
    close();
};

const close = () => {
    isOpen.value = false;
};

defineExpose({isOpen});
</script>

  <style scoped>
  /* Add any component-specific styling here */
  </style>
