import {createApp} from 'vue';
import {createPinia} from 'pinia';
import App from './App.vue';
import router from './router';
import {pushNotificationsSetup} from './notifications';
import {App as CapacitorApp} from '@capacitor/app';
import {Capacitor} from '@capacitor/core';

import {IonicVue} from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

import 'swiper/swiper-bundle.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(createPinia());

router.isReady().then(async () => {
    app.mount('#app');
    pushNotificationsSetup();

    if (Capacitor.isNativePlatform()) {
        const {Keyboard} = await import('@capacitor/keyboard');
        Keyboard.setAccessoryBarVisible({isVisible: true});
    }

    CapacitorApp.addListener('appUrlOpen', (data) => {
        const url = new URL(data.url);
        const pathSegments = url.pathname.split('/').filter((p) => p);

        if (pathSegments[0] === 'register') {
            const invitationId = url.searchParams.get('invitation_id');
            if (invitationId) {
                router.push({path: `/register`, query: {invitation_id: invitationId}});
            } else {
                router.push({path: '/register'});
            }
        } else if (pathSegments[1] === 'confirm-email') {
            const language = pathSegments[0];
            const token = pathSegments[2];
            router.push({path: `/${language}/confirm-email/${token}`});
        } else if (pathSegments[0] === 'profile-view' && pathSegments[1] === 'single-post') {
            const postId = pathSegments[2];
            router.push({path: `/profile-view/single-post/${postId}`});
        } else if (pathSegments[0] === 'single-product') {
            const productId = pathSegments[1];
            router.push({path: `/single-product/${productId}`});
        }
        // Add other conditions as needed
    });
});
