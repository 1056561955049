import {
    ActionPerformed,
    PushNotifications,
    PushNotificationSchema,
    Token,
} from '@capacitor/push-notifications';
import {useUserStore} from './stores/user';
import * as api from './api/apiCommon';
import {Capacitor} from '@capacitor/core';
import {alertController} from '@ionic/vue';

let token: string;
let notificationToOpenAfterLogin: PushNotificationSchema | null = null;

export function pushNotificationsSetup() {
    if (Capacitor.getPlatform() === 'web') {
        console.warn('push notifications not implemented for web');
        return;
    }

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then((result) => {
        if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
        } else {
            console.error('push notification permission not granted: ', result.receive);
        }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
        async (t: Token) => {
            token = t.value;
            registerPushNotificationTokenForCurrentUser();
        },
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
        (error: any) => {
            console.error('Error on registration: ' + JSON.stringify(error));
        },
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
        async (notification: PushNotificationSchema) => {
            const alert = await alertController.create({
                header: notification.title,
                message: notification.body,
                buttons: [
                    {
                        text: 'close',
                        role: 'cancel',

                    },
                    {
                        text: 'show',
                        role: 'confirm',

                    },
                ],
            });

            await alert.present();

            const {role} = await alert.onDidDismiss();

            if (role === 'cancel') {
                return;
            }
            executeAction(notification);
        },
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
        ({notification}: ActionPerformed) => {
            executeAction(notification);
        },
    );
}

async function executeAction(notification: PushNotificationSchema) {
    try {
        // TODO
    } catch (e) {
        console.error('push notification action error', e);
    }
}

async function registerPushNotificationTokenForCurrentUser() {
    try {
        const store = useUserStore();
        await store.readPushNotificationToken();
        if (store.user && store.pushNotificationToken?.token !== token) {
            const _api = api.getApi();
            if (!_api) {
                return;
            }
            const {id} = await _api.setNotificationToken(store.pushNotificationToken?.id, token);
            store.setPushNotificationToken({id, token});
        }
    } catch (e) {
        console.error('push notification error while storing token', e);
    }
}

export async function pushNotificationsUserSetup() {
    await registerPushNotificationTokenForCurrentUser();
    if (notificationToOpenAfterLogin) {
        await executeAction(notificationToOpenAfterLogin);
        notificationToOpenAfterLogin = null;
    }
}

export async function deletePushNotificationTokenForCurrentUser() {
    try {
        const store = useUserStore();
        await store.readPushNotificationToken();
        if (store.pushNotificationToken?.token) {
            const _api = api.getApi();
            if (!_api) {
                return;
            }
            await _api.deleteNotificationToken(store.pushNotificationToken?.id);
            store.deletePushNotificationToken();
        }
    } catch (e) {
        console.error('push notification error while deleting token', e);
    }
}
