// userApi.ts
import {getApi} from './apiCommon';
import {UserInfo} from '../framework2-sdk/f2Api';
import config from '../config';
import {User} from '@/stores/user';
import {Invitation} from '@/stores/user';
import {Product} from '@/stores/product';
// import {Customer} from '@/stores/customer';
import {Category} from '@/stores/recommendedProducts';


export interface CreateInvitationInput {
    name: string;
    surname: string;
    email: string;
    Supplier_UID: string | undefined;
    Customer_UID: string | undefined;
    admin: boolean;
}

const userDataDefinition = `{
    F2_ID
    ID
    name
    surname
    email
    currentCompany{
        __typename
        uid
        Name 
        Description
        username
        profileImage
        ... on Supplier {
            postsAggregate(where: { active: true, published: true }) {
              count
            }
            producedAggregate {
              count
            }
          }
    }
    currentCompanyConnection {
        edges {
          admin
        }
      }
    favoriteSuppliers {
        uid
        Name
        username
        profileImage
    }
}`;

export async function fetchUserDetails(): Promise<UserInfo | null> {
    const api = getApi();
    if (api) {
        const userInfo: UserInfo = await api.userInfo();
        return userInfo;
    }
    return null;
}

export async function fetchUserByEmail(email: string): Promise<User | null> {
    const api = getApi();
    if (api) {
        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `
                    query FetchUserByEmail($email: String!) {
                        fetchUserByEmail(where: {email: $email}) {
                            ID
                            F2_ID
                            name
                            surname
                            email
                        }
                    }
                `,
                email: email,
            },
        }).then((result) => result.data?.fetchUserByEmail || null);
    }
    return null;
}
export async function checkUserAndCompanyByEmail(email: string):
 Promise<{
   data: { ID: string; currentCompany: { uid: string; Name: string; }|null; }|null; ID: string; currentCompany: { uid: string; Name: string } | null
} | null> {
    const api = getApi();
    if (api) {
        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `query CheckUserAndCompanyByEmail($email: String!) {
                    checkUserAndCompanyByEmail(email: $email) {
                        ID
                        name
                        surname
                        currentCompany {
                            uid
                        }
                    }
                }`,
                variables: {email: email},
            },
        }).then((result) => result.data?.checkUserAndCompanyByEmail || null);
    }
    return null;
}

export async function fetchUserByID(userID: number | undefined): Promise<User | null> {
    const api = getApi();
    if (api) {
        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `
                    query FetchUserByID($userID: Int!) {
                        fetchUserByID(userID: $userID) ${userDataDefinition}
                    }
                `,
                variables: {
                    userID: userID,
                },
            },
        }).then((result) => result.data?.fetchUserByID || null);
    }
    return null;
}

export async function updateUserPersonalProfile(F2_ID: number | undefined, userData: Partial<User>): Promise<User | null> {
    const api = getApi();
    if (api) {
        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `
                    mutation UpdateUserPersonalProfile($F2_ID: Int!, $userData: UserUpdateInput!) {
                        updateUsers(
                            where: { F2_ID: $F2_ID },
                            update: $userData
                        ) {
                            users ${userDataDefinition}
                        }
                    }
                `,
                variables: {
                    F2_ID: F2_ID,
                    userData: {
                        name: userData.name,
                        surname: userData.surname,
                        email: userData.email,
                    },
                },
            },
        }).then((result) => result.data?.updateUsers?.users?.[0] || null);
    }
    return null;
}

export async function createInvitation(input: CreateInvitationInput): Promise<any> {
    const api = getApi();
    api?.setLanguage('it-it');
    if (api) {
        await api?.callApi({
            url: config.graphqlURL + '/invite',
            method: 'POST',
            data: input,
        }).then((result) => result.data?.createInvitations?.info || null);
    }
    return null;
}

export async function fetchCompanyMembersAndInvitations(companyType: string | undefined, companyUID: string | undefined):
    Promise<{ members: User[], invitations: Invitation[] }> {
    const entity = `${companyType?.toLowerCase()}s`;

    const api = getApi();
    if (api) {
        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `
                    query FetchCompanyMembersAndInvitations($companyUID: ID!) {
                        ${entity} (where: { uid: $companyUID }) {
                            users {
                                name
                                surname
                                email
                                F2_ID
                                invitationsReceived {
                                    createdAt
                                    status
                                }
                            }
                            invitations (where: { createdUser: null }) {
                                ID
                                createdAt
                                name
                                surname
                                email
                                status
                            }
                        }
                    }`,
                variables: {
                    companyUID: companyUID,
                },
            },
        }).then((result) => {
            const company = result.data?.[entity]?.pop() || {};
            return {
                members: company?.users || [],
                invitations: company?.invitations || [],
            };
        });
    }
    return {members: [], invitations: []};
}

export async function toggleFavoriteSupplier(userID: string, supplierUID: string, isCurrentlyFavorite: boolean): Promise<any> {
    const api = getApi();
    if (!api) return null;

    let mutationString; let operationVariables;

    if (isCurrentlyFavorite) {
        // If it's currently a favorite, then remove
        mutationString = `
            mutation RemoveFavoriteSupplier($disconnect: UserDisconnectInput, $where: UserWhere) {
              updateUsers(disconnect: $disconnect, where: $where) {
                users {
                  ID
                  favoriteSuppliersAggregate {
                    count
                  }
                }
              }
            }
        `;
        operationVariables = {
            disconnect: {
                favoriteSuppliers: [
                    {
                        where: {
                            node: {
                                uid: supplierUID,
                            },
                        },
                    },
                ],
            },
            where: {
                ID: userID,
            },
        };
    } else {
        // If it's not a favorite, then add
        mutationString = `
            mutation UpdateUser($connect: UserConnectInput, $where: UserWhere) {
              updateUsers(connect: $connect, where: $where) {
                users {
                  ID
                  favoriteSuppliersAggregate {
                    count
                  }
                }
              }
            }
        `;
        operationVariables = {
            connect: {
                favoriteSuppliers: [
                    {
                        where: {
                            node: {
                                uid: supplierUID,
                            },
                        },
                    },
                ],
            },
            where: {
                ID: userID,
            },
        };
    }

    return api.callApi({
        url: config.graphqlURL,
        method: 'POST',
        data: {
            query: mutationString,
            variables: operationVariables,
        },
    }).then((result) => result.data?.updateUsers?.users?.[0] || null);
}
export async function fetchFavoriteProducts(userFilter: any, productFilter? : any): Promise<Product[]> {
    const api = getApi();
    if (api) {
        return await api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `                query Query($where: UserWhere, $favoriteProductsWhere: ProductWhere) {
                    users(where: $where) {
                        favoriteProducts(where: $favoriteProductsWhere) {
                            Name
                            Availability
                            Bio
                            Category
                            GlutenFree
                            Price
                            Subcategory
                            Subcategory_ID
                            producedBy {
                                uid
                              }
                            Vegan
                            uid
                        }
                    }
                }
                `,
                variables: {
                    where: userFilter,
                    favoriteProductsWhere: productFilter??{},
                },
            },
        }).then((result) => result.data?.users?.[0]?.favoriteProducts || []);
    }
    return [];
}

// Function to add a product to favorites
export async function addProductToFavorites(userId: string, productId: string): Promise<any> {
    const api = getApi();
    if (!api) return null;

    return api.callApi({
        url: config.graphqlURL,
        method: 'POST',
        data: {
            query: `
                mutation AddToFavorites($connect: UserConnectInput, $where: UserWhere) {
                  updateUsers(connect: $connect, where: $where) {
                    users {
                      ID
                      favoriteProducts {
                        uid
                        Name
                      }
                    }
                  }
                }
            `,
            variables: {
                connect: {
                    favoriteProducts: [
                        {
                            where: {
                                node: {
                                    uid: productId,
                                },
                            },
                        },
                    ],
                },
                where: {
                    ID: userId,
                },
            },
        },
    }).then((result) => result.data?.updateUsers?.users?.[0] || null);
}

// Function to remove a product from favorites
export async function removeProductFromFavorites(userId: string, productId: string): Promise<any> {
    const api = getApi();
    if (!api) return null;

    return api.callApi({
        url: config.graphqlURL,
        method: 'POST',
        data: {
            query: `
                mutation RemoveFromFavorites($disconnect: UserDisconnectInput, $where: UserWhere) {
                  updateUsers(disconnect: $disconnect, where: $where) {
                    users {
                      ID
                      name
                    }
                  }
                }
            `,
            variables: {
                disconnect: {
                    favoriteProducts: [
                        {
                            where: {
                                node: {
                                    uid: productId,
                                },
                            },
                        },
                    ],
                },
                where: {
                    ID: userId,
                },
            },
        },
    }).then((result) => result.data?.updateUsers?.users?.[0] || null);
}
export async function fetchProvinces(): Promise<{ Province: string; ID: number }[]> {
    const api = getApi();
    if (api) {
        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `query Provinces {
                    provinces {
                        Province
                        ID
                    }
                }`,
            },
        }).then((result) => result.data?.provinces || []);
    }
    return [];
}
export async function connectUserToCompany(email: string, companyUID: string): Promise<any> {
    const api = getApi();
    if (api) {
        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `mutation ConnectUserToCompany($email: String!, $companyUid: String!) {
                    connectUserToCompany(email: $email, companyUID: $companyUid) {
                        message
                        success
                    }
                }`,
                variables: {
                    email: email,
                    companyUid: companyUID,
                },
            },
        }).then((result) => result.data?.connectUserToCompany || null);
    }
    return null;
}
export async function fetchFoodPreferences(): Promise<{ likedFoods: Category[], dislikedFoods: Category[], hasCompletedOnboarding: boolean }> {
    const api = getApi();
    if (api) {
        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `
                    query GetFoodPreferences {
                        getFoodPreferences {
                            likedFoods {
                                ID
                                Name
                            }
                            dislikedFoods {
                                ID
                                Name
                            }
                            hasCompletedOnboarding
                        }
                    }
                `,
            },
        }).then((result) => result.data?.getFoodPreferences || {likedFoods: [], dislikedFoods: [], hasCompletedOnboarding: false});
    }
    return {likedFoods: [], dislikedFoods: [], hasCompletedOnboarding: false};
}

// Replace the existing saveFoodPreferences function with this:
export async function saveFoodPreferences(likedFoods: Category[], dislikedFoods: Category[]): Promise<boolean> {
    const api = getApi();
    if (api) {
        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `
                    mutation SaveFoodPreferences($likedFoods: [ID!]!, $dislikedFoods: [ID!]!) {
                        saveFoodPreferences(likedFoods: $likedFoods, dislikedFoods: $dislikedFoods) {
                            ID
                            hasCompletedOnboarding
                        }
                    }
                `,
                variables: {
                    likedFoods: likedFoods.map((food) => food.ID),
                    dislikedFoods: dislikedFoods.map((food) => food.ID),
                },
            },
        }).then((result) => !!result.data?.saveFoodPreferences);
    }
    return false;
}

// Add this new function:
export async function setOnboardingStatus(status: boolean): Promise<boolean> {
    const api = getApi();
    if (api) {
        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `
                    mutation SetOnboardingStatus($status: Boolean!) {
                        setOnboardingStatus(status: $status) {
                            ID
                            hasCompletedOnboarding
                        }
                    }
                `,
                variables: {status},
            },
        }).then((result) => !!result.data?.setOnboardingStatus);
    }
    return false;
}
