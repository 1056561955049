<template>
    <ion-modal
        :is-open="reviewModalStore.isReviewModalVisible"
        class="modal-page"
        @did-dismiss="handleModalDismiss"
    >
        <ion-content class="ion-padding">
            <div class="review-form">
                <div class="star-rating-container">
                    <ion-button
                        v-for="index in 5"
                        :key="index"
                        class="star-button"
                        fill="clear"
                        @click="rate(index)"
                    >
                        <ion-icon
                            :icon="index <= currentRating ? starIcon : starOutlineIcon"
                            class="star-icon"
                            :style="{ color: index <= currentRating ? 'gold' : 'grey' }"
                        />
                    </ion-button>
                </div>
                <p class="rating-encouragement">
                    Il tuo feedback ci aiuta a migliorare!
                </p>
                <ion-textarea
                    v-model="reviewText"
                    placeholder="Scrivi qui la tua recensione..."
                    class="review-textarea"
                    rows="6"
                />
                <ion-button
                    expand="block"
                    class="submit-button"
                    fill="solid"
                    @click="submitReview"
                >
                    Invia recensione
                </ion-button>
            </div>
        </ion-content>
    </ion-modal>
</template>

<script setup>
import {
    IonModal,
    IonButton,
    IonContent,
    IonTextarea,
    IonIcon,
    toastController,
} from '@ionic/vue';
import {star, starOutline} from 'ionicons/icons';
import {useReviewStore} from '@/stores/reviewStore';
import {ref} from 'vue';

const currentRating = ref(0);
const reviewText = ref('');
const starIcon = star;
const starOutlineIcon = starOutline;
const textareaFocused = ref(false);
const reviewModalStore = useReviewStore();

const rate = (index) => {
    currentRating.value = index;
};

const showToast = async (message, isError = false) => {
    const toast = await toastController.create({
        message: message,
        duration: 3000,
        position: 'top',
        color: isError ? 'danger' : undefined,
    });
    toast.present();
};

const submitReview = async () => {
    if (currentRating.value === 0) {
        showToast('Per favore, assegna una valutazione con le stelle prima di inviare la recensione.', true);
        return;
    }

    try {
        const reviewInput = {
            content: reviewText.value,
            rating: currentRating.value,
            reviewedProduct: {
                connect: {
                    where: {
                        node: {
                            uid: reviewModalStore.productUid, // Replace with actual product ID
                        },
                    },
                    overwrite: true,
                },
            },
        };

        await reviewModalStore.submitReview(reviewInput);
        dismissModal();
        resetForm();
    } catch (error) {
        console.error('Failed to submit review:', error);
        showToast('Si è verificato un errore durante l\'invio della recensione.', true);
    }
};

const dismissModal = () => {
    textareaFocused.value = false;
    reviewModalStore.hideReviewModal();
};

const resetForm = () => {
    currentRating.value = 0;
    reviewText.value = '';
};

const handleModalDismiss = () => {
    resetForm();
    reviewModalStore.hideReviewModal();
};
</script>

<style scoped>
.modal-page {
  --height: 65vh;
  --border-radius: 20px;
  --box-shadow: 0 12px 28px -4px rgb(0 0 0 / 20%), 0 8px 16px -8px rgb(0 0 0 / 10%);
}

.ion-toolbar {
  --background: #fff;
}

.star-button {
  --size: 6vw;
  transition: transform 0.3s ease;
}

.star-button:hover {
  transform: scale(1.1);
}

.star-icon {
  font-size: 4vw;
}

.review-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
}

.review-textarea {
  width: 100%;
  min-height: 30vh;
  margin-bottom: 24px;
  border: 1px solid #ccc;
  border-radius: 12px;
  font-size: 4vw;
  padding: 16px;
}

.submit-button {
  --background: var(--ion-color-primary);
  --border-radius: 8px;
}

.rating-encouragement {
  text-align: center;
  margin: 10px 0 20px;
  font-size: 3.5vw;
  color: var(--ion-color-medium);
}
</style>
