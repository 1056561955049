import {defineStore} from 'pinia';
import {useCartStore} from '@/shared/store/cartStore';
import {calculatePrice} from '@/shared/store/cartStore';


export const useCartModalStore = defineStore({
    id: 'cartModal',
    state: () => ({
        isCartModalVisible: false,
        selectedProduct: null as any | null,
        quantity: '1',
        noteContent: null as string | null,
        initialModalBreakpoint: 0.5,
        isNoteVisible: false,
        selectedUnityPrice: 'PZ', // Set default to PZ
    }),
    getters: {
        totalPrice: (state) => {
            if (state.selectedProduct) {
                return calculatePrice({
                    product: state.selectedProduct,
                    quantity: parseFloat(state.quantity),
                    selectedUnityPrice: state.selectedUnityPrice,
                });
            }
            return '0.00';
        },
    },
    actions: {
        toggleNoteVisibility() {
            this.isNoteVisible = !this.isNoteVisible;
            this.initialModalBreakpoint = this.isNoteVisible ? 0.7 : 0.5;
        },
        showCartModal(product: any) {
            const cartStore = useCartStore();
            this.selectedProduct = product;
            // Check if the product is already in the cart and update quantity, noteContent, and selectedUnityPrice
            const existingCartItem = cartStore.cartItems.find((item: any) => item.product.uid === product.uid);
            if (existingCartItem) {
                this.quantity = existingCartItem.quantity.toString();
                this.noteContent = existingCartItem.note;
                this.selectedUnityPrice = existingCartItem.selectedUnityPrice || 'PZ';
            } else {
                this.quantity = '1';
                this.noteContent = null;
                this.selectedUnityPrice = 'PZ';
            }
            this.isNoteVisible = this.noteContent !== null;
            this.initialModalBreakpoint = this.isNoteVisible ? 0.7 : 0.5;

            this.isCartModalVisible = true;
        },
        hideCartModal() {
            this.isCartModalVisible = false;
            this.selectedProduct = null;
            this.quantity = '1';
            this.noteContent = null;
            this.isNoteVisible = false;
            this.initialModalBreakpoint = 0.4;
            this.selectedUnityPrice = 'PZ'; // Reset to default
        },
        async addProductToCart() {
            const cartStore = useCartStore(); // Access cartStore from within cartModalStore
            if (this.selectedProduct) {
                await cartStore.addProductToCart(this.selectedProduct, parseFloat(this.quantity), this.noteContent, this.selectedUnityPrice);
                this.hideCartModal();
            }
        },
    },
});
