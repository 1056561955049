import {getApi} from './apiCommon';
import config from '../config';
import {Product} from '../stores/product'; // Import API functions

import {Category} from '@/stores/recommendedProducts';
export async function fetchProducts(
    filter?: any,
    options?: { offset?: number; limit?: number },
    currentCompanyUid?: string,
): Promise<Product[]> {
    const api = getApi();
    if (api) {
        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `query Query($where: ProductWhere, $options: ProductOptions, $currentCompanyUid: CustomerWhere) {
                products(where: $where, options: $options) {
                  uid
                  Name
                  Price
                  listedAtConnection {
                    edges {
                      price
                    }
                  }
                  Subcategory
                  Vegan
                  GlutenFree
                  Category
                  Bio
                  Availability
                  Available
                  Published
                  UnityPrice
                  UnityWeight
                  MediumUnityWeight
                  img 
                  description
                  usersFavorite {
                    F2_ID
                    ID
                  }
                  producedBy{
                    uid
                    Name
                    profileImage
                    VAT_Number
                  }
                  reviews {
                    content
                    createdAt
                    id
                    user {
                      name
                      surname
                    }
                    likesAggregate {
                      count
                    }
                    likes {
                      ID
                    }
                  }
                  reviewsConnection {
                    totalCount
                  }
                  reviewsAggregate {
                    node {
                      rating {
                        sum
                      }
                    }
                  }
                  category {
                              SoldByUnit
                              img
                          }
                  boughtByCustomer(where: $currentCompanyUid) {
                    Name
                  }
                }
              }`,
                variables: {
                    where: filter,
                    options: options,
                    currentCompanyUid: currentCompanyUid ? {uid: currentCompanyUid} : undefined,
                },
            },
        }).then((result) => result.data?.products || []);
    }
    return [];
}

export async function addReview(reviewInput: any): Promise<any> {
    const api = getApi();
    if (api) {
        const currentDate = new Date().toISOString();
        reviewInput.createdAt = currentDate;
        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `mutation Mutation($input: [ReviewCreateInput!]!) {
                createReviews(input: $input) {
                  reviews {
                    content
                    createdAt
                  }
                }
              }`,
                variables: {
                    input: [reviewInput],
                },
            },
        }).then((result) => result.data?.createReviews?.reviews || []);
    }
    return [];
}


export async function fetchAllCategories(): Promise<Category[]> {
    const api = getApi();
    if (api) {
        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `
                  query {
                      categories {
                          ID
                          Name
                          img
                          SoldByUnit
                      }
                  }
              `,
            },
        }).then((result) => result.data?.categories || []);
    }
    return [];
}
