// customerStore.ts
import {defineStore} from 'pinia';
import {fetchCustomerDetail,
    disconnectCurrentUserFromCompany, updateDescriptionAndProfileImage, createCustomerWithUser,
    checkForDuplicateCompany} from '../api/customer'; // Import API functions

import {Invitation, User, useUserStore} from './user';


export interface CustomerState {
    customers: Customer[];
    customerSkip: number;
    selectedCustomer: Customer | null;
    currentFilter: any;

}
export interface Customer {
    uid: string;
    Name: string;
    Description:string;
    Province: string;
    Region: string;
    Location: { latitude: number; longitude: number };
    Address: string;
    Cap: string;
    Position: string;
    users: User[];
    invitations: Invitation[];
    members: any[];
    UsersCount: number;
    __typename:string;
    username: string;
    profileImage: string;
    VAT_Number: number;
  }


export interface InvitationInput {
    name: string;
    surname: string;
    email: string;
    Customer_UID: string;
  }

export const useCustomerStore = defineStore({
    id: 'customer',
    state: () => ({
        customers: [],
        customerSkip: 0,
        selectedCustomer: null,
        currentFilter: null,

    } as CustomerState),
    actions: {

        setSelectedCustomer(customer: Customer) {
            this.selectedCustomer = customer;
        },
        async updateDescriptionAndProfileImage(customerUID: string, newDescription: string, newProfileImageUrl: string, newUsername: string) {
            // Call the API to update the customer's description and profile image in the database
            const userStore = useUserStore();

            const company = await updateDescriptionAndProfileImage(customerUID, newDescription, newProfileImageUrl, newUsername);
            userStore.updateCompany(company); // Update the user store with the updated company data
        },
        async fetchAndSetSelectedCustomer(customerUID: string) {
            try {
                const customerDetail = await fetchCustomerDetail(customerUID);
                if (!customerDetail) {
                    console.error(`No supplier found with ID ${customerUID}`);
                    return;
                }
                this.setSelectedCustomer(customerDetail);
            } catch (error) {
                console.error('Error fetching supplier detail:', error);
            }
        },
        async disconnectCurrentUser() {
            try {
                const result = await disconnectCurrentUserFromCompany();
                if (result) {
                    console.log('User successfully disconnected from company');
                } else {
                    console.error('Failed to disconnect user from company');
                }
            } catch (error) {
                console.error('Error while disconnecting user:', error);
            }
        },
        async createCustomerWithUser(input: any) {
            try {
                const newCustomer = await createCustomerWithUser(input);
                if (newCustomer) {
                    console.log('Customer created successfully:', newCustomer);
                } else {
                    console.error('Failed to create customer');
                }
            } catch (error) {
                console.error('Error creating customer:', error);
            }
        },
        async checkForDuplicateCompany(fields: {
            username?: string;
            contactNumber?: string;
            contactEmail?: string;
            partitaIva?: string;
        }) {
            try {
                return await checkForDuplicateCompany(fields);
            } catch (error) {
                console.error('Error checking for duplicates:', error);
                return false;
            }
        },
    },
});
