// userApi.ts
import {getApi} from '@/api/apiCommon';
import config from '@/config';
import {CartItemInput} from '@/shared/store/cartStore';
export async function addProductToCart(productId: string, quantity: number, note?:string|null, selectedUnityPrice?:string|null): Promise<any | null> {
    const api = getApi();
    if (api) {
        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `
                    mutation AddProductToCart($productId: ID!, $quantity: Float!, $note: String, $selectedUnityPrice : String) {
                      addProductToCart(product_id: $productId, quantity: $quantity, note: $note, selectedUnityPrice : $selectedUnityPrice) {
                        success
                        message
                        cartItem {
                          ID
                          quantity
                          selectedUnityPrice
                        }
                      }
                    }
                `,
                variables: {
                    productId,
                    quantity,
                    note,
                    selectedUnityPrice,
                },
            },
        }).then((result) => result.data?.addProductToCart?.cartItem || null);
    }
    return null;
}


// In userApi.ts or cartApi.ts

export async function fetchActiveCartItems(): Promise<any | null> {
    const api = getApi();
    if (api) {
        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `
                    query Carts($where: CartWhere) {
                      carts(where: $where) {
                        ID
                        note
                        items {
                          ID
                          addedAt
                          quantity
                          note
                          updatedAt
                          selectedUnityPrice
                          product {
                            Name
                            uid
                            img
                            Price
                            Category
                            UnityPrice
                            UnityWeight
                            MediumUnityWeight
                            category {
                                SoldByUnit
                            }
                            listedAtConnection {
                                edges {
                                  price
                                }
                              }
                          }
                        }
                      }
                    }
                `,
                variables: {
                    where: {
                        status: 'ACTIVE',
                    },
                },
            },
        }).then((result) => {
            if (result.data?.carts.length > 0) {
                return {
                    cartId: result.data.carts[0].ID,
                    items: result.data.carts[0].items,
                };
            }
            return {cartId: null, items: []};
        });
    }
    return null;
}


export async function fetchCartItemById(cartItemId: string): Promise<any | null> {
    const api = getApi();
    if (api) {
        return api
            .callApi({
                url: config.graphqlURL,
                method: 'POST',
                data: {
                    query: `
                    query CartItems($where: CartItemWhere) {
                      cartItems(where: $where) {
                        ID
                        note
                        cart {
                          ID  
                        }
                        addedAt
                        quantity
                        note
                        selectedUnityPrice
                        product {
                          Name
                          uid
                          img
                          Price
                          Category
                          UnityPrice
                          UnityWeight
                          MediumUnityWeight
                          category {
                                SoldByUnit
                            }
                          listedAtConnection {
                            edges {
                              price
                            }
                          }
                        }
                      }
                    }
                `,
                    variables: {
                        where: {
                            ID: cartItemId,
                        },
                    },
                },
            })
            .then((result) => {
                if (result.data?.cartItems.length > 0) {
                    const cartItem = result.data.cartItems[0];
                    return {
                        cartId: cartItem.cart.ID, // Now also returning the cartId
                        cartItem: cartItem,
                    };
                }
                return null;
            });
    }
    return null;
}


export async function confirmCart(cartId: string, note:string|null, cartItems: CartItemInput[]): Promise<any | null> {
    const api = getApi();
    if (api) {
        const mutation = `
        mutation ConfirmCart($cartId: ID!,$note:String,  $cartItems: [CartItemInput!]!) {
          confirmCart(cartId: $cartId, note:$note, cartItems: $cartItems) {
            success
            message
            cart {
              ID
              status
            }
          }
        }
      `;

        // Prepare variables for the mutation
        const variables = {
            cartId,
            note,
            cartItems,
        };

        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: mutation,
                variables,
            },
        }).then((result) => result.data?.confirmCart || null);
    }
    return null;
}
export async function adminRemoveCartItem(cartId: string, itemId: string): Promise<any | null> {
    const api = getApi();
    if (api) {
        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `
                  mutation DeleteCartItems($where: CartItemWhere!) {
                      deleteCartItems(where: $where) {
                          nodesDeleted
                          relationshipsDeleted
                      }
                  }
              `,
                variables: {
                    where: {
                        ID: itemId,
                        cart: {
                            ID: cartId,
                        },
                    },
                },
            },
        }).then((result) => result.data?.deleteCartItems || null);
    }
    return null;
}

export async function deleteCartItem(cartItemId: string): Promise<any | null> {
    const api = getApi();
    if (api) {
        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `
                    mutation DeleteCartItem($where: CartItemWhere) {
                        deleteCartItems(where: $where) {
                        nodesDeleted
                        relationshipsDeleted
                      }
                    }
                `,
                variables: {
                    where: {
                        ID: cartItemId,
                    },
                },
            },
        }).then((result) => result.data?.deleteCartItems || null);
    }
    return null;
}

export async function fetchCheckedOutCarts(): Promise<any | null> {
    const api = getApi();
    if (api) {
        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `
                query Query($where: CartWhere, $options: CartOptions) {
                  carts(where: $where, options: $options) {
                    ID
                    createdAt
                    confirmedAt
                    status
                    note
                    items {
                      ID
                      price
                      quantity
                      note
                      selectedUnityPrice
                      product {
                        uid
                        Name
                        UnityPrice
                        UnityWeight
                        MediumUnityWeight
                        producedBy {
                          Name
                        }
                        category {
                          SoldByUnit
                          img
                        }
                      }
                    }
                    customer {
                      Name
                    }
                  }
                }
            `,
                variables: {
                    where: {
                        status: 'CHECKED_OUT',
                    },
                    options: {
                        sort: {
                            confirmedAt: 'DESC',
                        },
                    },
                },
            },
        }).then((result) => result.data?.carts || null);
    }
    return null;
}
