import {defineStore} from 'pinia';
import {addReview} from '../api/product';


export interface ReviewState {
    isReviewModalVisible: boolean;
    productUid: string | null;
}

export const useReviewStore = defineStore({
    id: 'review',
    state: () => ({
        isReviewModalVisible: false,
        productUid: null,
    } as ReviewState),
    actions: {
        showReviewModal(productUid: string) {
            this.productUid = productUid;
            this.isReviewModalVisible = true;
        },
        hideReviewModal() {
            this.isReviewModalVisible = false;
            this.productUid = null;
        },
        async submitReview(reviewInput: any) {
            try {
                const result = await addReview(reviewInput);
                return result;
            } catch (error) {
                console.error('Error submitting review:', error);
                throw error;
            }
        },
    },
});
