// customerApi.ts
import {getApi} from './apiCommon';
import config from '../config';
import {Customer} from '../stores/customer';

export async function fetchCustomers(
    filter?: any,
    options?: { offset?: number; limit?: number },
): Promise<Customer[]> {
    const api = getApi();
    if (api) {
        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `query( $options: CustomerOptions) {
                    customers( options: $options) {
                    uid
                    Name
                    Description
                    Province
                    Region
                    Location { latitude longitude }
                    Address
                    Cap
                    VAT_Number
                    Position
                    username
                    users { 
                        name,
                        surname,
                        email,
                        F2_ID
                        invitationsReceived{
                            createdAt
                            status
                        }
                     } 
                }
            }`,
                variables: {
                    options: options,
                },
            },
        }).then((result) => result.data?.customers || []);
    }
    return [];
}

export async function fetchCustomerDetail(customerUID: string): Promise<Customer | null> {
    const api = getApi();
    if (api) {
        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `
                    query FetchCustomerDetail($customerUID: ID!) {
                        customers(where: {uid: $customerUID}) {
                            uid
                            Name
                            Description
                            Province
                            Region
                            Location { latitude longitude }
                            Address
                            Cap
                            Position
                            users { 
                                name
                                surname
                                email
                                F2_ID
                                invitationsReceived {
                                    createdAt
                                    status
                                }
                            }
                        }
                    }
                `,
                variables: {
                    customerUID,
                },
            },
        }).then((result) => result.data?.customers?.[0] || null);
    }
    return null;
}

// eslint-disable-next-line max-len
export async function updateDescriptionAndProfileImage(companyUID: string|undefined, newDescription: string, newProfileImageUrl: string, newUsername: string): Promise<Customer> {
    const api = getApi();
    const mutationString = `
        mutation ($where: CustomerWhere, $update: CustomerUpdateInput){
            updateCustomers(where: $where, update: $update) {
                customers {
                    Description
                    profileImage
                    username
                }
            }
        }`;

    return await api?.callApi({
        url: config.graphqlURL,
        method: 'POST',
        data: {
            query: mutationString,
            variables: {
                where: {
                    uid: companyUID,
                },
                update: {
                    profileImage: newProfileImageUrl,
                    Description: newDescription,
                    username: newUsername,
                },
            },
        },
    }).then((result) => {
        return result.data?.updateCustomers?.customers.pop() || null;
    });
}
export async function disconnectCurrentUserFromCompany(): Promise<{success: boolean, message: string, user?: any} | null> {
    const api = getApi();
    if (api) {
        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `
                    mutation {
                        disconnectCurrentUserFromCompany {
                            success
                            message
                            user {
                                ID
                                name
                                email
                            }
                        }
                    }
                `,
            },
        }).then((result) => {
            if (result.data) {
                return {
                    success: result.data.disconnectCurrentUserFromCompany.success,
                    message: result.data.disconnectCurrentUserFromCompany.message,
                    user: result.data.disconnectCurrentUserFromCompany.user,
                };
            }
            return null;
        });
    }
    return null;
}

export async function createCustomerWithUser(input: any): Promise<any> {
    const api = getApi();
    if (api) {
        return api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `
                    mutation CreateCustomerWithUser($input: [CustomerCreateInput!]!) {
                        createCustomers(input: $input) {
                            customers {
                                Name
                            }
                        }
                    }
                `,
                variables: {
                    input: input,
                },
            },
        }).then((result) => result.data?.createCustomers?.customers?.pop() || null);
    }
    return null;
}
export async function checkForDuplicateCompany(fields: {
    username?: string;
    contactNumber?: string;
    contactEmail?: string;
    partitaIva?: string;
}): Promise<boolean> {
    const api = getApi();
    if (api) {
        const query = `
            query CheckForDuplicates($where: CustomerWhere) {
                customers(where: $where) {
                    uid
                }
            }
        `;
        const variables: any = {where: {}};

        if (fields.username) variables.where.username = fields.username;
        if (fields.contactNumber) variables.where.contactNumber = fields.contactNumber;
        if (fields.contactEmail) variables.where.contactEmail = fields.contactEmail;
        if (fields.partitaIva) variables.where.VAT_Number = fields.partitaIva;

        const result = await api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {query, variables},
        });

        return result.data?.customers.length > 0;
    }
    return false;
}
